<template>
  <el-form
    v-if="configs && configs.length"
    ref="paramsForm"
    :model.sync="paramsForm"
    :label-width="`${labelWidth}px`"
    :disabled="disabled"
    :rules="rules"
  >
    <template v-for="(item, index) in configs">
      <!-- 自定义 item-->
      <template v-if="item.type === 'SlotItem'">
        <slot :name="item.prop" :data="paramsForm[item.prop]"></slot>
      </template>

      <el-form-item
        v-else
        :key="item.prop || index"
        :prop="item.prop || ''"
        :label="item.label || ''"
        :required="readOnly ? false : item.required"
        :rules="readOnly ? [] : item.rules"
        :disabled="item.disabled"
      >
        <span v-if="readOnly || item.readOnly">
          {{ item.type === "PassWord" ? "******" : paramsForm[item.prop] }}
        </span>

        <div v-else>
          <!-- 输入框 -->
          <el-input
            v-if="item.type === 'Input'"
            v-model.trim="paramsForm[item.prop]"
            :maxlength="item.maxlength || null"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            @input="item.inputFunc"
          ></el-input>

          <!-- 密码输入框 -->
          <el-input
            v-else-if="item.type === 'PassWord'"
            v-model.trim="paramsForm[item.prop]"
            show-password
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          ></el-input>

          <!-- 文本域 -->
          <el-input
            v-else-if="item.type === 'Textarea'"
            show-word-limit
            type="textarea"
            v-model="paramsForm[item.prop]"
            :rows="item.rows || 4"
            :maxlength="item.maxlength || null"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          ></el-input>

          <!-- 选择控件 -->
          <el-select
            v-else-if="item.type === 'Select'"
            v-model="paramsForm[item.prop]"
            :placeholder="item.placeholder"
          >
            <el-option
              v-for="optionItem in item.opction"
              :key="optionItem.value"
              :value="optionItem.value"
              :label="optionItem.label"
            ></el-option>
          </el-select>

          <!-- 自定义 输入部分-->
          <template v-else-if="item.type === 'Slot'">
            <slot :name="item.prop" :data="paramsForm[item.prop]"></slot>
          </template>
        </div>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
/**
 * 通用form表单
 * 由于我们已经在rules配置了自定义校验规则，所以我们必须将el-form-item标签内部的required去掉 不然会出现英文提示
 */
import { DEEP_CLONE } from "@/utils/common";

export default {
  name: "LSForm",
  props: {
    configs: {
      type: Array,
      default: () => [],
    },
    paramsData: {
      type: Object,
      default: () => {},
    },
    labelWidth: {
      type: Number,
      default: 80,
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 只读模式
    readOnly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    paramsForm: {},
  }),
  watch: {
    paramsData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.paramsForm = (newVal && DEEP_CLONE(newVal)) || {};
      },
    },
  },
  methods: {
    // 获取表单数据
    getFormData() {
      return this.paramsForm;
    },
    // 赋值
    setParamsField(prop, value) {
      if (prop) {
        this.$set(this.paramsForm, prop, value);
      }
    },
    // 清除提示信息
    clearValidate(prop) {
      if (prop) {
        this.$nextTick(() => {
          this.$refs["paramsForm"].clearValidate(prop);
        });
      }
    },
    // 校验单个
    validateField(props) {
      if (props) {
        this.$nextTick(() => {
          this.$refs["paramsForm"].validateField(props);
        });
      }
    },
    // 提交
    submitForm() {
      return new Promise((resolve) => {
        this.$refs["paramsForm"].validate((valid) => {
          if (valid) {
            resolve(this.paramsForm);
          } else {
            return false;
          }
        });
      });
    },
    // 重置
    resetForm() {
      this.$refs["paramsForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 24px;
}

:deep(.el-select) {
  width: 100%;
}

:deep(.el-textarea__inner) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
}
</style>
