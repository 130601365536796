<template>
  <section>
    <el-upload
      ref="uploadImg"
      action="#"
      accept="image/jpeg,image/png"
      list-type="picture-card"
      :show-file-list="true"
      :limit="limit"
      :disabled="isDisabled"
      :auto-upload="true"
      :before-upload="beforeUpload"
      :on-change="handleOnChange"
      :on-remove="handleOnRemove"
      :http-request="uploadRequest"
      :class="{ hide: isDisabled }"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :width="`20%`">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </section>
</template>

<script>
/**
 * 上传图片
 */
export default {
  name: "UploadImg",
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    tip: String,
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
    };
  },
  computed: {
    isDisabled() {
      let count = this.fileList.length;
      return count < this.limit ? false : true;
    },
  },
  watch: {
    fileList: {
      handler(newVal) {
        const list = this.dealFiles(newVal);
        this.$emit("onChange", list || []);
      },
    },
    deep: true,
  },
  methods: {
    // 上传前拦截
    beforeUpload(file) {
      if (!file) return false;
      const { size, type } = file || {};
      if (!type.includes("image/")) {
        this.$message.info("请上传图片格式的文件！");
        return false;
      }
      if (!size) {
        return false;
      }
      if (size > 5242880) {
        this.$message.info("最大支持5M的图片格式文件！");
        return false;
      }
    },
    // 监听文件变化
    handleOnChange(file, fileList) {
      this.fileList = fileList;
    },
    // 监听文件移除
    handleOnRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 删除
    handleRemove(file) {
      this.$refs["uploadImg"].handleRemove(file);
    },
    // 查看
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传方法
    uploadRequest() {},
    // 获取文件
    getFiles() {
      return this.dealFiles(this.fileList);
    },
    dealFiles(fileList = []) {
      let data = fileList || [];
      let list = [];
      data.forEach((item) => {
        if (item.raw) {
          list.push(item.raw);
        }
      });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  &:deep(.el-upload--picture-card) {
    display: none !important;
  }
}

.image-box {
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    border: 1px solid #dddddd;
  }

  .icon-box {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    i {
      margin: 0 10px;
      font-size: 18px;
      color: #fff;
    }
  }

  &:hover {
    .icon-box {
      opacity: 1;
    }
  }
}

:deep(.el-upload--picture-card) {
  width: 90px;
  height: 90px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-upload-list__item) {
  width: 90px;
  height: 90px;
  border: 1px solid #dddddd;

  img {
    width: 88px;
    height: 88px;
    object-fit: cover;
  }
}
</style>
