<template>
  <section class="ls-container report-problem">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="report-problem-wrap main-section" v-loading="loading">
      <Form
        ref="paramsForm"
        class="form-box"
        :configs="formConfigs"
        :labelWidth="labelWidth"
        :paramsData="paramsData"
      >
        <template v-slot:infoTitle>
          <div class="subtitle" :style="{ paddingLeft: '40px' }">上报问题</div>
        </template>

        <template v-slot:hint>
          <div class="hint-text">
            尊敬的平台用户，您好，
            <br />您可以通过当前页面提交遇到的问题或者是您的疑问，并留下您的联系方式，我们会在收到后尽快提供反馈信息。
          </div>
        </template>

        <template v-slot:images>
          <UploadImg
            ref="uploadImgRef"
            :limit="6"
            :tip="'支持bmp，jpg或者png格式，图片大小不超过5M，最多可以上传6张'"
            @onChange="changeUploadImg"
          ></UploadImg>
        </template>

        <template v-slot:code>
          <div class="flex alignCenter code-box">
            <el-input
              v-model="code"
              class="code-input"
              placeholder="请输入验证码"
              @input="codeInput"
            ></el-input>
            <div class="flex alignCenter code-image">
              <img v-if="codeImg" :src="codeImg" class="image" />
              <span @click="getCaptchaCode">换一张</span>
            </div>
          </div>
        </template>
      </Form>

      <div class="ls-btns-block btn" @click="submitFunc">提交</div>
    </div>
  </section>
</template>

<script>
/**
 * 上报问题
 */
import BreadCrump from "@/components/common/BreadCrump";
import Form from "@/components/common/Form";
import UploadImg from "@/components/common/UploadImg";
import { mapGetters } from "vuex";
import { saveFeedback } from "@/api/interface/feedback.js";
import axios from "axios";

export default {
  name: "ReportProblem",
  components: {
    BreadCrump,
    Form,
    UploadImg,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "上报问题" }],
      labelWidth: 120,
      paramsData: {},
      formConfigs: [
        {
          type: "SlotItem",
          prop: "infoTitle",
        },
        {
          type: "Slot",
          prop: "hint",
          label: "",
        },
        {
          type: "readOnly",
          prop: "phone",
          label: "用户名",
          readOnly: true,
        },
        {
          type: "Input",
          prop: "lxfs",
          label: "联系方式",
          placeholder: "请输入联系方式",
          maxlength: 11,
          require: null,
          rules: [
            { required: true, message: "请输入联系方式", trigger: "blur" },
            {
              pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
          ],
        },
        {
          type: "Select",
          prop: "wtlx",
          label: "问题类型",
          placeholder: "请选择问题类型",
          require: null,
          rules: {
            required: true,
            message: "请选择问题类型",
            trigger: "change",
          },
          opction: [
            {
              label: "充值问题",
              value: 0,
            },
            {
              label: "其它",
              value: 1,
            },
          ],
        },
        {
          type: "Textarea",
          prop: "desc",
          label: "问题描述",
          placeholder: "请输入问题描述，限1000字",
          require: null,
          rows: 5,
          maxlength: 1000,
          rules: { required: true, message: "请输入问题描述", trigger: "blur" },
        },
        {
          type: "Slot",
          prop: "images",
          label: "图片/截图上传",
          require: true,
          rules: { required: true, message: "请上传图片", trigger: "change" },
        },
        {
          type: "Slot",
          prop: "code",
          label: "验证码",
          require: null,
          rules: { required: true, message: "请输入验证码", trigger: "blur" },
        },
      ],
      code: undefined,
      codeImg: undefined,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "deviceInfo"]),
  },
  methods: {
    codeInput() {
      this.paramsData = this.$refs["paramsForm"].getFormData();
      this.$set(this.paramsData, "code", this.code);
    },
    changeUploadImg(fileList) {
      this.paramsData = this.$refs["paramsForm"].getFormData();
      this.$set(this.paramsData, "images", fileList);
    },
    submitFunc() {
      this.$refs["paramsForm"].submitForm().then((res) => {
        this.$confirm("确认提交？", "提示", {
          type: "warning",
          center: true,
        }).then((_) => {
          this.loading = true;
          const formData = this.$refs["paramsForm"].getFormData();
          this.uploadImgAction()
            .then(({ code, data }) => {
              if (code == 200) {
                saveFeedback({
                  captchaCode: formData.code,
                  content: formData.desc,
                  fileUrl: data,
                  phone: formData.lxfs,
                  type: formData.wtlx,
                })
                  .then(() => {
                    this.$message.success("问题反馈成功。");
                    this.$router.push({ name: "FuelManage" });
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        });
      });
    },
    getCaptchaCode() {
      axios({
        method: "get",
        url: `${this.deviceInfo.baseUrl}/v1/workbench/feedback/getCaptchaImage`,
        responseType: "blob",
        headers: {
          Authorization: this.userInfo.authorization,
        },
      }).then(({ data }) => {
        if (data) {
          this.codeImg = (window.URL || window.webkitURL).createObjectURL(data);
        }
      });
    },
    uploadImgAction() {
      const fileArr = this.$refs.uploadImgRef.getFiles();
      const formData = new FormData();
      fileArr.forEach((item) => {
        formData.append("files", item);
      });
      return axios({
        method: "post",
        url: `${this.deviceInfo.baseUrl}/v1/workbench/feedback/file/upload`,
        headers: {
          Authorization: this.userInfo.authorization,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(({ data }) => {
        return data;
      });
    },
  },
  created() {
    this.getCaptchaCode();
  },
  mounted() {
    const { phone } = this.userInfo;
    this.paramsData = {
      phone,
    };
  },
};
</script>

<style lang="scss" scoped>
.report-problem {
  .report-problem-wrap {
    width: 100%;
    margin-top: 16px;
    background-color: #fff;
    min-height: calc(100vh - 170px);
    padding: 60px 200px;

    .form-box {
      .subtitle {
        font-size: 18px;
        color: #1d8db9;
        margin-bottom: 15px;
        font-weight: 600;
      }

      .hint-text {
        font-size: 12px;
        line-height: 20px;
        color: #8a8a8a;
      }

      .annotation {
        font-size: 12px;
        color: #8a8a8a;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
      }

      .code-box {
        .code-input {
          width: 150px;
        }

        .code-image {
          margin-left: 10px;
          cursor: pointer;
          .image {
            width: 100px;
            height: 40px;
            border: 1px solid #1d8db9;
          }
          span {
            margin-left: 10px;
            font-size: 14px;
            color: #1d8db9;
          }
        }
      }

      &:deep(.el-upload--picture-card) {
        width: 90px;
        height: 90px;
        border: 1px solid #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:deep(.el-upload-list__item) {
        width: 90px;
        height: 90px;
        border: 1px solid #dddddd;

        img {
          width: 88px;
          height: 88px;
        }
      }
    }

    .btn {
      margin-top: 15px;
      margin-left: 120px;
    }
  }
}

:deep() .el-input {
  width: 290px;
}
:deep() .el-select {
  width: 290px;
  min-width: 290px !important;
}
:deep() .el-textarea {
  width: 557px;
  .el-textarea__inner {
    resize: none;
  }
}
:deep .el-form-item__label {
  color: #3d3d3d;
}
:deep() .el-form-item__content {
  font-size: 14px;
  color: #adadad;
}
</style>
