<template>
  <el-breadcrumb class="ls-com-breadcrumb" separator="> ">
    <el-breadcrumb-item
      v-for="(item, i) in breadcrumbList"
      :to="{ name: item.routeName ? item.routeName : '' }"
      :key="i"
      :class="[item.curCss]"
      >{{ item.menuName }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {},
  props: {
    breadcrumbList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.ls-com-breadcrumb {
  box-sizing: border-box;
  padding: 0 15px;
  :deep() .el-breadcrumb__item {
    .el-breadcrumb__inner {
      font-size: 18px;
      line-height: 25px;
      cursor: pointer;
      color: #3D3D3D;
      font-weight: bold;
      vertical-align: middle;
    }
    .el-breadcrumb__separator {
      color: #0846AC;
      font-weight: bold;
    }
    &.cur {
      .el-breadcrumb__inner {
        color: #0846AC;
      }
    }
  }
}
</style>